import React, { useEffect, useState } from "react";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  const toggleVisibility = () => {
    const scrolled = window.pageYOffset;
    const height = document.documentElement.scrollHeight - window.innerHeight;
    const scrolledPercentage = (scrolled / height) * 100;

    setScrollProgress(scrolledPercentage);
    setIsVisible(scrolled > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        className="fixed z-50 right-8 bottom-8 h-12 w-12 cursor-pointer rounded-full text-primary-500 border-none flex items-center justify-center text-3xl shadow-lg transition-opacity duration-200 ease-in-out opacity-0 hover:opacity-100"
        style={{
          transition: "opacity 200ms linear",
          opacity: isVisible ? 1 : 0,
        }}
      >
        <svg className="absolute inset-0 w-full h-full" viewBox="0 0 36 36">
          <path
            className="text-primary-200"
            fill="none"
            stroke="currentColor"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            strokeWidth="1"
          ></path>
          <path
            className="text-primary-500"
            fill="none"
            stroke="currentColor"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            strokeWidth="1"
            strokeDasharray="100, 100"
            style={{ strokeDashoffset: 100 - scrollProgress }}
          ></path>
        </svg>
        ↑
      </button>
    )
  );
};

export default BackToTopButton;
